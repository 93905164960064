import { isControlCharacter } from "@component-utils/validations"
import type { Ref } from "vue"
import { stopAndPrevent } from "~/features/_abstract/utils/event"

export function handleEvents (props: { readonly?: boolean, disabled?: boolean, whitelist?: string[] }, validationActive: Ref<boolean>) {
  const handlePaste = (event: ClipboardEvent) => {
    if (props.readonly || props.disabled) return
    if (!props.whitelist) return
  
    const pastedText = event.clipboardData?.getData('text/plain')
    if (pastedText) {
      if (pastedText.split('').every((character) => props.whitelist?.includes(character))) {
        return
      }
  
      stopAndPrevent(event)
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    validationActive.value = true
  
    if (props.whitelist) {
      const key = event.key
      if (event.ctrlKey || event.altKey || event.metaKey || isControlCharacter(key) || props.whitelist.includes(key)) {
        return
      }
  
      stopAndPrevent(event)
    }
  }  

  return {
    handlePaste,
    handleKeyDown
  }
}

