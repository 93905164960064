import { ref, watch, type Ref } from "vue"

export function useBuffering <T>(modelValue: Ref<T>, props: { buffered?: boolean }) {
  if (props.buffered) {
    const modelValueInternal = ref(modelValue.value)

    watch(modelValue, (value) => modelValueInternal.value = value)

    return {
      modelValueInternal,
      updateModelValue: () => {
        modelValue.value = modelValueInternal.value
      }
    }
  } else {
    return {
      modelValueInternal: modelValue,
      updateModelValue: () => {}
    }
  }
}